var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c(
                "b-calendar",
                _vm._b(
                  {
                    attrs: {
                      "date-info-fn": _vm.dateClass,
                      locale: "it",
                      "hide-header": true,
                      "start-weekday": "1",
                      max: _vm.max,
                    },
                    on: { context: _vm.onContext, selected: _vm.onSelected },
                    model: {
                      value: _vm.inputVal,
                      callback: function ($$v) {
                        _vm.inputVal = $$v
                      },
                      expression: "inputVal",
                    },
                  },
                  "b-calendar",
                  _vm.labels["it"] || {},
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }